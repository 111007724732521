import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import Homepage from './components/homepage/Homepage';
import Sorting from './components/sorting/Sorting';

class App extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route exact path="/sorting" component={Sorting} />
      </Switch>
    );
  }
}

export default withRouter(App);
