import React, { Component } from "react";
import profile from "../../images/apphotocircle.jpg";
import "./Homepage.css";
import { Link } from "react-router-dom";

class Homepage extends Component {
  componentDidMount() {
    document.body.style.background = getComputedStyle(document.documentElement)
      .getPropertyValue('--main-bg-color');
  }

  render() {
    return (
      <div className="homepage">
        <Navbar />
        <Profile />
        <Section />
      </div>
    );
  }
}

const Navbar = () => {
  return (
    <nav>
      <div className="navbar">
        <ul className="navlist">
          <li id="resume">
            <a href="https://andreipascu.com/resume.pdf" target="_blank" rel="noopener noreferrer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M24 21h-3l1-3h1l1 3zm-12.976-4.543l8.976-4.575v6.118c-1.007 2.041-5.607 3-8.5 3-3.175 0-7.389-.994-8.5-3v-6.614l8.024 5.071zm11.976.543h-1v-7.26l-10.923 5.568-11.077-7 12-5.308 11 6.231v7.769z" />
              </svg>
              <span>/ Resume</span>
            </a>
          </li>
          <li id="email">
            <a href="mailto:andrei.pascu@yale.edu" target="_top">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M19 9.062s-5.188-.333-7 1.938c2-4.896 7-5.938 7-5.938v-2l5 4-5 4.019v-2.019zm-18.974 14.938h23.947l-11.973-11.607-11.974 11.607zm1.673-14l10.291-7.488 3.053 2.218c.712-.459 1.391-.805 1.953-1.054l-5.006-3.637-11.99 8.725v12.476l7.352-7.127-5.653-4.113zm15.753 4.892l6.548 6.348v-11.612l-6.548 5.264z" />
              </svg>
              <span>/ Email</span>
            </a>
          </li>
          <li id="linkedin">
            <a href="https://www.linkedin.com/in/andrei-pascu/" target="_blank" rel="noopener noreferrer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
              </svg>
              <span>/ LinkedIn</span>
            </a>
          </li>
          <li id="github">
            <a href="https://github.com/andreiui" target="_blank" rel="noopener noreferrer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
              </svg>
              <span>/ GitHub</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

const Profile = () => {
  return (
    <div className="photo">
      <img id="intro-circle" src={profile} alt="apphoto.jpg not found" />
      <span>
        <h3 className="heading">Hi, I'm </h3>
        <h1 className="heading">Andrei Pascu</h1>
        <p className="p">
          <b>Undergraduate student at Yale University</b>
        </p>
        <p className="p">Computer Science and Economics</p>
      </span>
    </div>
  );
};

const Section = () => {
  return (
    <section>
      <div className="container">
        <div className="line">
          <h2 className="heading">About me</h2>
          <p className="p">I'm a sophomore at Yale interested in software engineering, data analysis, and AI/ML that produces valuable economic results.</p>
          <div className="table">
            <div className="table-header">
              <p className="p" id="category">Projects</p>
            </div>
            <div className="resources">
              <div className="resource">
                <Link to="/sorting" target="_blank">
                  <p className="p" id="title">Sorting Visualized</p>
                  <p className="p" id="description">
                    <small>
                      A React application designed as an interactive educational app for a visual demonstration of sorting algorithms
                    </small>
                  </p>
                </Link>
              </div>
              <div className="resource">
                <a href="https://github.com/andreiui/simple-programs" target="_blank" rel="noopener noreferrer">
                  <p className="p" id="title">Simple Programs</p>
                  <p className="p" id="description">
                    <small>
                    A TypeScript library of data structures and computational math puzzles that illustrate topics such as hash tables, RSA encryption, and blockchain
                    </small>
                  </p>
                </a>
              </div>
              <div className="resource">
                <a href="https://github.com/andreiui/swift-calculator" target="_blank" rel="noopener noreferrer">
                  <p className="p" id="title">Swift Calculator</p>
                  <p className="p" id="description">
                    <small>
                      An iOS calculator app replica, inspired from the native iOS version
                    </small>
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Homepage;
